<template>
    <entering-form-layout>
        <div class="authentication__form-wrapper">
            <div class="authentication__form">
                <div class="forgot-section form-block">
                    <form
                        class="form-block__body"
                        @submit.prevent="validate"
                    >
                        <div class="form-block__header">
                            <h2 class="form-block__title">
                                Forgot Password?
                            </h2>
                            <p class="form-block__description">
                                We'll send you an email with a reset link
                            </p>
                        </div>
                        <form-input
                            v-model.trim="emailValue"
                            legend="Email address"
                            prepend-icon="EmailOutlineIcon"
                            type="text"
                            name="email"
                            :field.sync="emailValue"
                            :errors="validationMessage($v.email)"
                            :is-valid="$v.email.$dirty && !$v.email.$anyError"
                            @input.native="$v.email.$touch()"
                            @blur.native="$v.email.$touch()"
                        />
                        <button class="btn-base btn-main">
                            <template v-if="loading">
                                <button-loader />
                            </template>
                            <span v-else>Reset password</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </entering-form-layout>
</template>

<script>
// vuelidate
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers } from 'vuex';
import formValidationMixin from '@/mixins/form-validation-mixin'
import { validationRules } from '@/validation/forgot-password/rules'
import { formMessages } from '@/validation/forgot-password/messages'

// elements
import FormInput from '@/components/common/form-elements/FormInput.vue';
import ButtonLoader from '@/components/common/ButtonLoader.vue';
import EnteringFormLayout from '@/layouts/EnteringFormLayout';

// vuex
import {
    ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL
} from '@/store/modules/client/action-types';
import { eventBus } from '@/helpers/event-bus/'

const { mapActions: mapClientActions } = createNamespacedHelpers('client');

export default {
    components: {
        FormInput,
        ButtonLoader,
        EnteringFormLayout
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    metaInfo: {
        title: 'Forgot Password?'
    },
    validations: {
        ...validationRules
    },
    data: () => ({
        email: '',
        loading: false,
        emailValue: ''
    }),
    watch: {
        emailValue(newValue) {
            this.email = newValue.trim()
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions([
            ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL
        ]),
        async submit() {
            try {
                this.loading = true
                await this[ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL]({
                    email: this.email
                })
                eventBus.$emit('showSnackBar', 'Please check your e-mail for instructions.', 'success');
                await this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.forgot-section {
    .form-block {
        &__footer {
            margin: 20px 0;
        }
    }
}
</style>
